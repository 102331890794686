body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100svh;
  width: 100%;
}

#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#map {
  width: 100%;
  height: 600px;
}

@font-face {
  font-family: 'BRShape';
  font-weight: 900;
  src: local('BRShape'), url(./fonts/BRShape-Super.otf) format('opentype');
}

@font-face {
  font-family: 'BRShape';
  src: local('BRShape'), url(./fonts/BRShape-Light.otf) format('opentype');
}
